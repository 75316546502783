import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTooth, faPhone } from '@fortawesome/free-solid-svg-icons'
import Bullseye from '../components/Bullseye'

import '../styles/find-a-dentist.scss'

const FindDentistPage = () => {
  return (
    <>
      <Layout>
        <SEO
          title="Lumineers Dentist | Find a Dentist"
          description="Ask your dentist if Lumineers® are right for you. If you do not have a dentist, view our growing directory of a select dentists that may be in your area. "
        />
        <Container fluid={true}>
          <Row>
            <Col md="12">
              <h2 className="mb-3 mt-5 text-center title">
                Find a Dentist with DDSLocator
              </h2>
            </Col>
          </Row>
          <Row className="pb-5">
            <Col md="4">
              <h2 className="mb-3 mt-5 text-center">1. Find a Dentist</h2>
              <p className="text-center">Search for dentists in your area</p>
              <div className="text-center">
                <FontAwesomeIcon icon={faSearch} size="5x" />
              </div>
            </Col>
            <Col md="4">
              <h2 className="mb-3 mt-5 text-center">2. Choose a Dentist</h2>
              <p className="text-center">View profile, services and reviews</p>
              <div className="text-center">
                <FontAwesomeIcon icon={faTooth} size="5x" />
              </div>
            </Col>
            <Col md="4">
              <h2 className="mb-3 mt-5 text-center">3. Contact a Dentist</h2>
              <p className="text-center">Complete form to contact dentist</p>
              <div className="text-center">
                <FontAwesomeIcon icon={faPhone} size="5x" />
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="mt-5">
          <Row>
            <Col md="12">
              <Bullseye site="Lumineers" />
              <div className="text-center">
                <em>
                  *DDSLocator requires cookies to be active in order to display
                  location services.
                </em>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <h2 className="mb-3 mt-5 text-center">Are you a Dentist?</h2>
              <p>
                Find out how DenMat can help drive more patients to your
                practice by joining our DDSLocator dental practice directory.
                Your practice will be listed on several patient-facing websites
                that draw thousands of visitors each month including
                Lumineers.com, SnapOnSmile.com, OralProCare.com and
                DDSLocator.com. You will be notified immediately when a lead has
                been generated for your practice so you can make contact and
                schedule an appointment. Just one new patient will likely pay
                for the service for the entire year!
              </p>
              <div className="text-center mt-5 mb-5">
                <a
                  href="https://www.denmat.com/memberships/ddslocator"
                  className="intro-button"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn More
                </a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <h2 className="mt-5 mb-5 text-center">More from DenMat</h2>
            </Col>
          </Row>
          <Row className="mt-3 mb-5">
            <Col xl="4">
              <div className="text-center">
                <h2 className="site-selection">
                  <a
                    href="https://www.oralprocare.com"
                    rel="noreferrer"
                    target="_blank"
                  >
                    OralProCare.com
                  </a>
                </h2>
                <p>Dentist recommended at-home oral hygiene products</p>
                <a
                  href="https://www.oralprocare.com"
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className="d-flex justify-content-center">
                    <StaticImage
                      src="../images/Oral-ProCare-logo-2-250x100.png"
                      alt="Oral ProCare Logo"
                      placeholder="none"
                      layout="fixed"
                      width={250}
                      height={100}
                    />
                  </div>
                </a>
              </div>
            </Col>
            <Col xl="4">
              <div className="text-center">
                <h2 className="site-selection">
                  <a
                    href="https://www.snaponsmile.com"
                    rel="noreferrer"
                    target="_blank"
                  >
                    SnapOnSmile.com
                  </a>
                </h2>
                <p>Dentist prescribed affordable cosmetic removable arch</p>
                <a
                  href="https://www.snaponsmile.com"
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className="d-flex justify-content-center">
                    <StaticImage
                      src="../images/Snap-On-Smile-logo-250x150.png"
                      alt="Snap-On Smile Logo"
                      placeholder="none"
                      layout="fixed"
                      width={250}
                      height={150}
                    />
                  </div>
                </a>
              </div>
            </Col>
            <Col xl="4">
              <div className="text-center">
                <h2 className="site-selection">
                  <a
                    href="https://www.ddslocator.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    DDSLocator.com
                  </a>
                </h2>
                <p>Get more patients with the DDSLocator directory</p>
                <a
                  href="https://www.ddslocator.com"
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className="d-flex justify-content-center">
                    <StaticImage
                      src="../images/DDSLocator-logo-horiz-250x60.jpg"
                      alt="DDSLocator Logo"
                      placeholder="none"
                      layout="fixed"
                      width={250}
                      height={60}
                    />
                  </div>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default FindDentistPage
